import { useNavigate } from "react-router-dom";
import { useSubjectQuery } from "./useSubjectQuery";
import { Eye, Trash } from "lucide-react";
import confirmDelete from "../../lib/confirmDelete";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useSubjectQuery();

  const handleEdit = (id) => {
    navigate(`/subjects/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error)
        });
      });
    });
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Code",
      accessorKey: "code",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Theory",
      accessorKey: "has_theory",
      cell: ({ row }) => (row.original.has_theory ? "Yes" : "No"),
    },
    {
      header: "Practical",
      accessorKey: "has_practical",
      cell: ({ row }) => (row.original.has_practical ? "Yes" : "No"),
    },
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => handleEdit(row.original.id)}
            className="text-blue-500 hover:text-blue-700"
          >
            <Eye size={18} />
          </button>
          <button
            onClick={() => handleDelete(row.original.id)}
            className="text-red-500 hover:text-red-700"
          >
            <Trash size={18} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
