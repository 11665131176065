import { useCallback } from "react";
import { useTableColumns } from "./useTableColumns";
import { useStudentQuery } from "./useStudentQuery";
import confirmDelete from "../../lib/confirmDelete";
import Swal from "sweetalert2";

export const useStudentsTable = () => {
  // Fetch students data and handle pagination
  const { data, error, isLoading, isFetching, pagination, deleteMutation, editMutation ,createMutation} = useStudentQuery();

  // Handle delete operation
  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error)
        });
      });
    });
  };

  // Table columns setup
  const columns = useTableColumns(handleDelete);

  return {
    columns,
    data,
    isLoading,
    isFetching,
    error,
    pagination,
    editMutation,
    deleteMutation,
    createMutation
  };
};
