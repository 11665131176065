import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNotice, deleteNotice, updateNotice } from "../../Api/noticesServices";
import { toast } from "react-toastify";

export const useNoticeQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createNotice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notices"] });
      toast.success("Notice created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create notice. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateNotice(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notices"] });
      toast.success("Notice updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update notice. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteNotice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notices"] });
      toast.success("Notice deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete notice. Please try again.");
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
