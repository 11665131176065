import axiosInstance from "../lib/axiosConfig";

export const createNotice = async (noticeData) => {
  try {
    const { data } = await axiosInstance.post('notices/', noticeData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotices = async ({ offset = 0, limit = 15, tag = null } = {}) => {
  try {
    const params = {
      offset,
      limit,
    };
    
    if (tag) {
      params.filter = `tags~${tag}`;
    }
    
    const response = await axiosInstance.get('notices/', {
      params,
    });
    return response.data?.data || [];
  } catch (error) {
    console.error("Error fetching notices:", error);
    throw error;
  }
};

export const getNoticeById = async (id) => {
  try {
    const { data } = await axiosInstance.get(`notices/${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching notice:", error);
    throw error;
  }
};

export const updateNotice = async (id, noticeData) => {
  try {
    const { data } = await axiosInstance.patch(`notices/${id}`, noticeData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteNotice = async (id) => {
  try {
    await axiosInstance.delete(`notices/${id}`);
  } catch (error) {
    throw error;
  }
};
