import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllStudents, deleteStudent, editStudent, createStudent } from "../../Api/studentsServices";
import { getProfileImage } from "../../Api/attachmentServices";
import usePagination from "../usePagination";
import { toast } from "react-toastify";

// Handles data fetching and pagination
export const useStudentQuery = () => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } = usePagination();

  // Fetching students with pagination
  const { data = { data: [], metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 } }, error, isLoading, isFetching } = useQuery({
    queryKey: ["students", offset, limit],
    queryFn: () => getAllStudents({ offset, limit }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: deleteStudent,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete student. Please try again.");
    },
  });

  // Edit mutation
  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => editStudent(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student updated successfully!");
    },
    onError: (error) => {
      if (error.response?.status === 422 && error.response?.data?.detail) {
        const detail = error.response.data.detail;
        // Handle array of error details
        if (Array.isArray(detail)) {
          detail.forEach(err => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          // Handle single error detail
          toast.error(error.response.data.detail);
        }
      } else {
        toast.error(error.response?.data?.message || "Failed to update student. Please try again.");
      }
    },
  });

  // Create Mutation
  const createMutation = useMutation({
    mutationFn: createStudent,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      toast.success("Student created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create student. Please try again.");
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    pagination: {
      currentPage,
      totalPages,
      nextPage: () => nextPage(totalPages),
      prevPage,
      goToPage: (page) => goToPage(page, totalPages),
    },
    deleteMutation,
    editMutation,
    createMutation
  };
};

// Handles fetching of student profile image
export const useStudentProfileImage = (studentId) => {
  return useQuery({
    queryKey: ['studentProfileImage', studentId],
    queryFn: () => getProfileImage(studentId),
    enabled: !!studentId,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnMount: false, // Don't refetch on component mount if data exists
  });
};