import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useClassQuery } from "../../hooks/class/useClassQuery";
import { getClassById } from "../../Api/classServices";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import LoadingDots from '../common/LoadingDots';

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const ClassForm = ({ id }) => {
  const [isSectionsExpanded, setIsSectionsExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { editMutation, createMutation } = useClassQuery();
  const navigate = useNavigate();

  const { data: selectedClass } = useQuery({
    queryKey: ["classes", id],
    queryFn: () => getClassById(id),
    enabled: !!id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      sections: [{ name: "", description: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
  });

  useEffect(() => {
    if (selectedClass) {
      reset(selectedClass);
      if (selectedClass.sections?.length > 0) {
        setIsSectionsExpanded(true);
      }
    }
  }, [selectedClass, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Convert empty strings to null
      const processedData = {
        ...data,
        description: data.description || null,
        sections: data.sections.map(section => ({
          ...section,
          description: section.description || null,
        })),
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/classes/all");
            },
            onError: () => {
              setIsSubmitting(false);
            }
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/classes/all");
          },
          onError: () => {
            setIsSubmitting(false);
          }
        });
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Class" : "Create New Class"}
      </h2>

      <div className="space-y-6">
        <div>
          <label className="form_label">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            className={`form_input ${errors.name && requiredFieldClass}`}
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && (
            <span className={errorMessageClass}>{errors.name.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Code <span className="text-red-500">*</span>
          </label>
          <input
            className={`form_input ${errors.code && requiredFieldClass}`}
            {...register("code", { required: "Code is required" })}
          />
          {errors.code && (
            <span className={errorMessageClass}>{errors.code.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Description <span className="text-gray-500 text-sm">Optional</span>
          </label>
          <textarea
            className="form_input"
            {...register("description")}
          />
        </div>

        <div className="mb-8">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={() => setIsSectionsExpanded(!isSectionsExpanded)}
          >
            <h3 className="text-lg font-semibold">Sections</h3>
            {isSectionsExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>

          {isSectionsExpanded && (
            <div className="mt-4 space-y-4">
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="p-4 border rounded-lg space-y-4"
                >
                  <div className="flex justify-between items-center">
                    <h4 className="font-medium">#{index + 1}</h4>
                    {fields.length > 1 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    )}
                  </div>

                  <div>
                    <label className="form_label">
                      Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      className={`form_input ${
                        errors.sections?.[index]?.name && requiredFieldClass
                      }`}
                      {...register(`sections.${index}.name`, {
                        required: "Section name is required",
                      })}
                    />
                    {errors.sections?.[index]?.name && (
                      <span className={errorMessageClass}>
                        {errors.sections[index].name.message}
                      </span>
                    )}
                  </div>

                  <div>
                    <label className="form_label">
                      Description <span className="text-gray-500 text-sm">Optional</span>
                    </label>
                    <textarea
                      className="form_input"
                      {...register(`sections.${index}.description`)}
                    />
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() => append({ name: "", description: "" })}
                className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Add Section
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end mt-6 gap-x-2">
        <button
          type="button"
          onClick={() => navigate("/classes/all")}
          className="px-4 py-2 border rounded-lg hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <LoadingDots text={id ? 'Updating' : 'Creating'} />
          ) : (
            id ? 'Update' : 'Create'
          )}
        </button>
      </div>
    </form>
  );
};

export default ClassForm;
