import { useNavigate } from "react-router-dom";
import { useClassQuery } from "./useClassQuery";
import { Edit, Eye, Trash } from "lucide-react";
import confirmDelete from "../../lib/confirmDelete";
import { Link } from "react-router-dom";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useClassQuery();

  const handleEdit = (id) => {
    navigate(`/classes/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error)
        });
      });
    });
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Code",
      accessorKey: "code",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Sections",
      accessorKey: "sections",
      cell: ({ row }) => {
        const sections = row.original.sections || [];
        return sections.map(section => section.name).join(", ");
      },
    },
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex gap-2" onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => handleEdit(row.original.id)}
            className="text-blue-500 hover:text-blue-700"
          >
          </button>
            <Eye size={18} />
            <Link to={`/classes/edit/${row.original.id}`}>
              <Edit size={18} />
            </Link>
          <button
            onClick={() => handleDelete(row.original.id)}
            className="text-red-500 hover:text-red-700"
          >
            <Trash size={18} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
