import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSubjects } from "../../Api/subjectServices";
import { useTableColumns } from "../../hooks/subject/useTableColumns";
import Table from "../../components/common/Table";

const ListSubject = () => {
  const navigate = useNavigate();
  const columns = useTableColumns();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: subjectsData, isLoading } = useQuery({
    queryKey: ["subjects", currentPage],
    queryFn: () => getSubjects(currentPage),
  });

  // Default pagination values
  const pagination = {
    currentPage: subjectsData?.currentPage || 1,
    totalPages: subjectsData?.totalPages || 1,
    nextPage: () => setCurrentPage(old => Math.min(old + 1, subjectsData?.totalPages || 1)),
    prevPage: () => setCurrentPage(old => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/subjects/create";

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
      </div>
      <Table
        data={subjectsData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Subject"
        baseEditPath="/subjects/edit"
      />
    </div>
  );
};

export default ListSubject;
