import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getUserById } from '../../Api/userServices';
import { useUserQuery } from '../../hooks/user/useUserQuery';
import LoadingDots from '../common/LoadingDots';

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useUserQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      phone: '',
      roles: ['user'],
      status: 'active',
      password: '',
    },
  });

  const { data: userData, isLoading } = useQuery({
    queryKey: ['users', id],
    queryFn: () => getUserById(id),
    enabled: !!id,
  });

  React.useEffect(() => {
    if (userData) {
      reset(userData);
    }
  }, [userData, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      const processedData = {
        ...data,
        roles: Array.isArray(data.roles) ? data.roles : [data.roles],
      };

      if (id) {
        // Remove password if it's empty in edit mode
        if (!processedData.password) {
          delete processedData.password;
        }
        
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate('/users/all');
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate('/users/all');
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? 'Edit User' : 'Create New User'}
      </h2>

      <div className="space-y-6">
        {/* Email & Username */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label className="form_label">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              className={`form_input ${errors.email && requiredFieldClass}`}
              {...register('email', { required: 'Email is required' })}
            />
            {errors.email && (
              <span className={errorMessageClass}>{errors.email.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">
              Username <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`form_input ${errors.username && requiredFieldClass}`}
              {...register('username', { required: 'Username is required' })}
            />
            {errors.username && (
              <span className={errorMessageClass}>{errors.username.message}</span>
            )}
          </div>
        </div>

        {/* First Name & Last Name */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label className="form_label">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`form_input ${errors.first_name && requiredFieldClass}`}
              {...register('first_name', { required: 'First name is required' })}
            />
            {errors.first_name && (
              <span className={errorMessageClass}>{errors.first_name.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`form_input ${errors.last_name && requiredFieldClass}`}
              {...register('last_name', { required: 'Last name is required' })}
            />
            {errors.last_name && (
              <span className={errorMessageClass}>{errors.last_name.message}</span>
            )}
          </div>
        </div>

        {/* Phone */}
        <div>
          <label className="form_label">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            className={`form_input ${errors.phone && requiredFieldClass}`}
            {...register('phone', { required: 'Phone is required' })}
          />
          {errors.phone && (
            <span className={errorMessageClass}>{errors.phone.message}</span>
          )}
        </div>

        {/* Password - Only show in create mode or if editing */}
        {!id && (
          <div>
            <label className="form_label">
              Password <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              className={`form_input ${errors.password && requiredFieldClass}`}
              {...register('password', { required: !id && 'Password is required' })}
            />
            {errors.password && (
              <span className={errorMessageClass}>{errors.password.message}</span>
            )}
          </div>
        )}

        {/* Roles */}
        <div>
          <label className="form_label">
            Roles <span className="text-red-500">*</span>
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2">
            {['admin', 'student', 'teacher', 'user'].map((role) => (
              <div key={role} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={role}
                  {...register('roles', { required: 'At least one role is required' })}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <label className="text-sm text-gray-600 capitalize">
                  {role}
                </label>
              </div>
            ))}
          </div>
          {errors.roles && (
            <span className={errorMessageClass}>{errors.roles.message}</span>
          )}
        </div>

        {/* Status */}
        <div>
          <label className="form_label">
            Status <span className="text-red-500">*</span>
          </label>
          <select
            className={`form_input ${errors.status && requiredFieldClass}`}
            {...register('status', { required: 'Status is required' })}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </select>
          {errors.status && (
            <span className={errorMessageClass}>{errors.status.message}</span>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/users/all')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isSubmitting ? 'Saving...' : id ? 'Update User' : 'Create User'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
