import React from 'react';

const LoadingDots = ({ text = '' }) => {
  return (
    <span className="animate-dot">
      {text}{[...Array(3)].map((_, i) => (
        <span key={i} className="animate-ping">
          .
        </span>
      ))}
    </span>
  );
};

export default LoadingDots;
