import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getNoticeById } from '../../Api/noticesServices';
import { useNoticeQuery } from '../../hooks/notice/useNoticeQuery';
import dayjs from 'dayjs';

const NoticeForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createMutation, editMutation } = useNoticeQuery();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    publish_date: dayjs().format('YYYY-MM-DD'),
    expiry_date: dayjs().add(30, 'day').format('YYYY-MM-DD'),
    teacher_id: null,
    class_id: null,
    tags: '',
    academic_year_id: 1,
  });

  useEffect(() => {
    if (id) {
      fetchNotice();
    }
  }, [id]);

  const fetchNotice = async () => {
    try {
      setLoading(true);
      const notice = await getNoticeById(id);
      setFormData({
        ...notice,
        publish_date: dayjs(notice.publish_date).format('YYYY-MM-DD'),
        expiry_date: dayjs(notice.expiry_date).format('YYYY-MM-DD'),
        tags: Array.isArray(notice.tags) ? notice.tags.join(', ') : '',
      });
    } catch (error) {
      console.error('Error fetching notice:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Convert comma-separated tags to array
      const tagsArray = formData.tags
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag !== '');

      const noticeData = {
        ...formData,
        tags: tagsArray,
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: noticeData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/notices");
            },
            onError: () => {
              setIsSubmitting(false);
            }
          }
        );
      } else {
        createMutation.mutate(noticeData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/notices");
          },
          onError: () => {
            setIsSubmitting(false);
          }
        });
      }
    } catch (error) {
      console.error('Error submitting notice:', error);
      setIsSubmitting(false);
    }
  };

  if (loading || createMutation.isPending || editMutation.isPending) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          {id ? 'Edit Notice' : 'Create New Notice'}
        </h2>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
              Content
            </label>
            <textarea
              id="content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="publish_date" className="block text-sm font-medium text-gray-700 mb-1">
                Publish Date
              </label>
              <input
                type="date"
                id="publish_date"
                name="publish_date"
                value={formData.publish_date}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label htmlFor="expiry_date" className="block text-sm font-medium text-gray-700 mb-1">
                Expiry Date
              </label>
              <input
                type="date"
                id="expiry_date"
                name="expiry_date"
                value={formData.expiry_date}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-1">
              Tags
            </label>
            <input
              type="text"
              id="tags"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter tags separated by commas (e.g., important, announcement, exam)"
            />
            <p className="mt-1 text-sm text-gray-500">
              Separate tags with commas (e.g., important, announcement, exam)
            </p>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={() => navigate('/notices')}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSubmitting ? (id ? 'Updating...' : 'Creating...') : (id ? 'Update Notice' : 'Create Notice')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoticeForm;
