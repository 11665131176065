import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useStudentQuery, useStudentProfileImage } from "../../hooks/student/useStudentQuery";
import AddressField from "../common/addressField";
import LoadingDots from '../common/LoadingDots';
import { getStudentById } from "../../Api/studentsServices";
import { getClasses } from "../../Api/classServices";
import { getAcademicYears } from "../../Api/academicYearServices";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import PhoneInput from 'react-phone-input-2';
import { uploadAttachment } from "../../Api/attachmentServices";
import 'react-phone-input-2/lib/style.css';

// CSS classes for form styling
const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const StudentForm = () => {
  const [isPrimaryExpanded, setIsPrimaryExpanded] = useState(false);
  const [isSecondaryExpanded, setIsSecondaryExpanded] = useState(false);
  const [isEnrollmentExpanded, setIsEnrollmentExpanded] = useState(false);
  const [availableSections, setAvailableSections] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const { editMutation, createMutation } = useStudentQuery();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: selectedStudent } = useQuery({
    queryKey: ["students", id],
    queryFn: () => getStudentById(id),
    enabled: !!id,
  });

  const { data: profilePicture, isLoading: isLoadingProfilePicture } = useStudentProfileImage(selectedStudent?.id);

  const { data: classesResponse = [] } = useQuery({
    queryKey: ["classes"],
    queryFn: () => getClasses(1, 100),
  });

  // Extract classes array from response
  const classes = Array.isArray(classesResponse.data) ? classesResponse.data : classesResponse;

  const { data: academicYears = [] } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      enrollments: [{ class_id: "", academic_year_id: "", section_id: "" }],
      dob: null,
      emis_id: null,
      admitted_on: !id ? new Date().toISOString().split('T')[0] : null,
      status: "active",
      parents: []
    },
  });

  const { fields: enrollmentFields, append: appendEnrollment, remove: removeEnrollment } = useFieldArray({
    control,
    name: "enrollments",
  });

  const { fields: parentFields, append: appendParent, remove: removeParent } = useFieldArray({
    control,
    name: "parents"
  });

  // Watch fields to determine if validation should be applied
  const parents = watch("parents");
  const enrollments = watch("enrollments");

  // Update available sections when class changes
  useEffect(() => {
    const newAvailableSections = {};
    enrollments?.forEach((enrollment, index) => {
      if (enrollment.class_id) {
        const selectedClass = classes?.find(c => String(c.id) === String(enrollment.class_id));
        if (selectedClass?.sections) {
          newAvailableSections[index] = selectedClass.sections;
          // Clear section selection if it's not valid for the new class
          const currentSectionId = enrollment.section_id;
          const isSectionValid = selectedClass.sections.some(
            s => String(s.id) === String(currentSectionId)
          );
          if (currentSectionId && !isSectionValid) {
            setValue(`enrollments.${index}.section_id`, "");
          }
        } else {
          newAvailableSections[index] = [];
        }
      }
    });
    setAvailableSections(newAvailableSections);
  }, [enrollments, classes, setValue]);

  useEffect(() => {
    if (selectedStudent) {
      const defaultValues = {
        ...selectedStudent,
        dob: selectedStudent.dob || null,
        emis_id: selectedStudent.emis_id || null,
        address: {
          municipality: selectedStudent.address?.municipality || "",
          ward: selectedStudent.address?.ward || "",
          district: selectedStudent.address?.district || "",
          province: selectedStudent.address?.province || "",
        },
        parents: selectedStudent.parents?.map(parent => ({
          id: parent.id,
          version: parent.version,
          first_name: parent.first_name,
          last_name: parent.last_name,
          dob: parent.dob,
          gender: parent.gender,
          phone: parent.phone,
          email: parent.email,
          notes: parent.notes,
        })) || [],
        enrollments: selectedStudent.enrollments?.length > 0 
          ? selectedStudent.enrollments 
          : [{ class_id: "", academic_year_id: "", section_id: "" }],
      };
      reset(defaultValues);

      if (selectedStudent.enrollments?.length > 0) {
        setIsEnrollmentExpanded(true);
      }
    }
  }, [selectedStudent, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Create a deep copy of the data to avoid mutating the original
      let formData = JSON.parse(JSON.stringify(data));

      // Convert empty strings to null for specific fields
      const fieldsToCheck = [
        'religion',
        'caste',
        'disability',
        'mother_tongue',
        'blood_group',
        'notes',
        'emis_id',
        'dob'
      ];

      fieldsToCheck.forEach(field => {
        if (formData[field] === '') {
          formData[field] = null;
        }
      });

      // Handle parent data
      const handleParentData = (parent, originalParent) => {
        if (!parent) return null;
        if (!parent.first_name && !Object.values(parent).some(value => value)) return null;
        
        const processedParent = {
          id: originalParent?.id,
          version: originalParent?.version,
          first_name: parent.first_name || null,
          last_name: parent.last_name || null,
          dob: parent.dob || null,
          gender: parent.gender || null,
          phone: parent.phone || null,
          email: parent.email || null,
          notes: parent.notes || null,
        };
        
        return processedParent;
      };

      // Get original parent data to preserve IDs and versions
      const originalParents = selectedStudent?.parents || [];
      formData.parents = formData.parents.map((parent, index) => handleParentData(parent, originalParents[index]));

      // Handle enrollment data
      if (formData.enrollments) {
        const hasEnrollmentData = formData.enrollments.some(enrollment => 
          enrollment.class_id || enrollment.academic_year_id || enrollment.section_id
        );

        if (!hasEnrollmentData) {
          formData.enrollments = [];
        } else {
          formData.enrollments = formData.enrollments.map(enrollment => ({
            ...enrollment,
            section_id: enrollment.section_id || null,
            class_id: enrollment.class_id || null,
            academic_year_id: enrollment.academic_year_id || null,
          }));
        }
      }

      // Handle address
      if (formData.address) {
        Object.keys(formData.address).forEach(key => {
          if (formData.address[key] === '') {
            formData.address[key] = null;
          }
        });
      }

      console.log('Submitting data:', formData);

      if (id) {
        editMutation.mutate(
          { id, updatedData: formData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/students/all");
            },
            onError: () => {
              setIsSubmitting(false);
            }
          }
        );
      } else {
        createMutation.mutate(formData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/students/all");
          },
          onError: () => {
            setIsSubmitting(false);
          }
        });
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-6xl mx-auto p-6 border rounded-lg"
    >
      {/* Profile Picture Section */}
      {id && (
        <div className="bg-white shadow-sm rounded-lg p-6 mb-8">
          <div className="flex items-start">
            <div className="relative">
              {profilePicture ? (
                <div className="relative">
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="w-24 h-24 rounded-full object-cover cursor-pointer hover:opacity-90 transition-opacity"
                    onClick={() => setShowImageModal(true)}
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                    <span className="text-white bg-black bg-opacity-50 px-2 py-1 rounded text-sm">
                      Click to enlarge
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center">
                  <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
              )}
              <label className="absolute bottom-0 right-0 bg-blue-600 rounded-full p-2 cursor-pointer hover:bg-blue-700 transition-colors">
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file && id) {
                      setUploadingPhoto(true);
                      uploadAttachment(id, file, 'profile_picture')
                        .then(() => {
                          // Invalidate the profile image query to trigger a refetch
                          queryClient.invalidateQueries(['studentProfileImage', selectedStudent.id]);
                        })
                        .catch((error) => {
                          console.error('Error uploading photo:', error);
                          alert('Failed to upload photo. Please try again.');
                        })
                        .finally(() => {
                          setUploadingPhoto(false);
                        });
                    }
                  }}
                />
                <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </label>
            </div>
            <div className="ml-6">
              <h1 className="text-2xl font-semibold text-gray-800">
                {selectedStudent?.first_name} {selectedStudent?.last_name}
              </h1>
              {(isLoadingProfilePicture || uploadingPhoto) && (
                <div className="text-sm text-blue-600 flex items-center mt-2">
                  Loading<LoadingDots />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white shadow-sm rounded-lg p-6 mb-8">
        <h3 className="text-lg font-semibold mb-4">Student's Detail <span className="text-sm text-gray-500">(* Required fields)</span></h3>
        <div className="grid grid-cols-2 gap-6 mb-4">
          <div>
            <label className="form_label">First Name <span className="text-red-500">*</span></label>
            <input
              className={`form_input ${errors.first_name && requiredFieldClass}`}
              type="text"
              {...register("first_name", { required: "First name is required" })}
            />
            {errors.first_name && (
              <span className={errorMessageClass}>{errors.first_name.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Last Name <span className="text-red-500">*</span></label>
            <input
              className={`form_input ${errors.last_name && requiredFieldClass}`}
              type="text"
              {...register("last_name", { required: "Last name is required" })}
            />
            {errors.last_name && (
              <span className={errorMessageClass}>{errors.last_name.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Date of Birth</label>
            <input
              className={`form_input ${errors.dob && requiredFieldClass}`}
              type="date"
              {...register("dob")}
            />
            {errors.dob && (
              <span className={errorMessageClass}>{errors.dob.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">EMIS ID</label>
            <input
              className={`form_input ${errors.emis_id && requiredFieldClass}`}
              type="text"
              {...register("emis_id")}
            />
            {errors.emis_id && (
              <span className={errorMessageClass}>{errors.emis_id.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Gender <span className="text-red-500">*</span></label>
            <select
              className={`form_input ${errors.gender && requiredFieldClass}`}
              {...register("gender", { required: "Gender is required" })}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && (
              <span className={errorMessageClass}>{errors.gender.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Status <span className="text-red-500">*</span></label>
            <select
              className={`form_input ${errors.status && requiredFieldClass}`}
              {...register("status", { required: "Status is required" })}
            >
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            {errors.status && (
              <span className={errorMessageClass}>{errors.status.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Religion</label>
            <select
              className="form_input"
              {...register("religion")}
            >
              <option value="">Select Religion</option>
              <option value="hinduism">Hinduism</option>
              <option value="islam">Islam</option>
              <option value="buddhism">Buddhism</option>
              <option value="christianity">Christianity</option>
              <option value="kirat_mundhum">Kirat Mundhum</option>
              <option value="prakriti">Prakriti</option>
              <option value="bon">Bon</option>
              <option value="sikhism">Sikhism</option>
              <option value="jainism">Jainism</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div>
            <label className="form_label">Caste</label>
            <select
              className="form_input"
              {...register("caste")}
            >
              <option value="">Select Caste</option>
              <option value="dalit">Dalit</option>
              <option value="janajati">Janajati</option>
              <option value="brahmin">Brahmin</option>
              <option value="chhetri">Chhetri</option>
              <option value="other">Other</option>
            </select>
            {errors.caste && (
              <span className={errorMessageClass}>{errors.caste.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Disability</label>
            <select
              className="form_input"
              {...register("disability")}
            >
              <option value="">Select Disability</option>
              <option value="none">None</option>
              <option value="physical">Physical</option>
              <option value="mental">Mental</option>
              <option value="deaf">Deaf</option>
              <option value="blind">Blind</option>
              <option value="low_vision">Low Vision</option>
              <option value="deaf_blind">Deaf-Blind</option>
              <option value="speech_impairment">Speech Impairment</option>
              <option value="multiple_disabilities">Multiple Disabilities</option>
              <option value="hearing">Hearing</option>
            </select>
            {errors.disability && (
              <span className={errorMessageClass}>{errors.disability.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Mother Tongue</label>
            <select
              className="form_input"
              {...register("mother_tongue")}
            >
              <option value="">Select Mother Tongue</option>
              <option value="nepali">Nepali</option>
              <option value="maithili">Maithili</option>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
              <option value="bhojpuri">Bhojpuri</option>
              <option value="gurung">Gurung</option>
              <option value="rai">Rai</option>
              <option value="tamang">Tamang</option>
              <option value="sherpa">Sherpa</option>
              <option value="sanskrit">Sanskrit</option>
              <option value="newari">Newari</option>
              <option value="kirati">Kirati</option>
              <option value="tharu">Tharu</option>
              <option value="magar">Magar</option>
              <option value="urdu">Urdu</option>
              <option value="rajbanshi">Rajbanshi</option>
              <option value="tajpuriya">Tajpuriya</option>
              <option value="majhi">Majhi</option>
              <option value="thami">Thami</option>
              <option value="dhimal">Dhimal</option>
              <option value="baitadeli">Baitadeli</option>
              <option value="achhami">Achhami</option>
              <option value="tibetian">Tibetian</option>
              <option value="doteli">Doteli</option>
              <option value="limbu">Limbu</option>
              <option value="sunuwar">Sunuwar</option>
              <option value="yakkha">Yakkha</option>
              <option value="bhujel">Bhujel</option>
              <option value="kulung">Kulung</option>
              <option value="kumal">Kumal</option>
              <option value="other">Other</option>
            </select>
            {errors.mother_tongue && (
              <span className={errorMessageClass}>{errors.mother_tongue.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Blood Group</label>
            <select
              className="form_input"
              {...register("blood_group")}
            >
              <option value="">Select Blood Group</option>
              <option value="a+">A+</option>
              <option value="a-">A-</option>
              <option value="b+">B+</option>
              <option value="b-">B-</option>
              <option value="ab+">AB+</option>
              <option value="ab-">AB-</option>
              <option value="o+">O+</option>
              <option value="o-">O-</option>
              <option value="unknown">Unknown</option>
            </select>
            {errors.blood_group && (
              <span className={errorMessageClass}>{errors.blood_group.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">Admitted On <span className="text-red-500">*</span></label>
            <input
              className={`form_input ${errors.admitted_on && requiredFieldClass}`}
              type="date"
              {...register("admitted_on", { required: "Admitted on is required" })}
            />
            {errors.admitted_on && (
              <span className={errorMessageClass}>{errors.admitted_on.message}</span>
            )}
          </div>

          <div className="mb-4 col-span-2">
            <label className="form_label">Notes</label>
            <textarea className="form_input" {...register("notes")} />
          </div>

        </div>

        <h3 className="text-lg font-semibold mb-4">Address Details</h3>
        <AddressField
          register={register}
          setValue={setValue}
          watch={watch}
          initialValue={selectedStudent?.address}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Parent Details</h3>
        
        {parentFields.map((field, index) => (
          <div key={field.id} className="mb-4 p-4 border rounded-lg bg-white">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-md font-medium">
                {index === 0 ? 'Primary Parent' : index === 1 ? 'Secondary Parent' : `Parent ${index + 1}`}
              </h4>
              <button
                type="button"
                onClick={() => removeParent(index)}
                className="text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>
            
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="form_label">First Name <span className="text-red-500">*</span></label>
                <input
                  className={`form_input ${errors.parents?.[index]?.first_name && requiredFieldClass}`}
                  type="text"
                  {...register(`parents.${index}.first_name`, { required: "First name is required" })}
                />
                {errors.parents?.[index]?.first_name && (
                  <span className={errorMessageClass}>{errors.parents[index].first_name.message}</span>
                )}
              </div>

              <div>
                <label className="form_label">Last Name <span className="text-red-500">*</span></label>
                <input
                  className={`form_input ${errors.parents?.[index]?.last_name && requiredFieldClass}`}
                  type="text"
                  {...register(`parents.${index}.last_name`, { required: "Last name is required" })}
                />
                {errors.parents?.[index]?.last_name && (
                  <span className={errorMessageClass}>{errors.parents[index].last_name.message}</span>
                )}
              </div>

              <div>
                <label className="form_label">Gender <span className="text-red-500">*</span></label>
                <select
                  className={`form_input ${errors.parents?.[index]?.gender && requiredFieldClass}`}
                  {...register(`parents.${index}.gender`, { required: "Gender is required" })}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.parents?.[index]?.gender && (
                  <span className={errorMessageClass}>{errors.parents[index].gender.message}</span>
                )}
              </div>

              <div>
                <label className="form_label">Phone</label>
                <PhoneInput
                  country={'np'}
                  value={watch(`parents.${index}.phone`)}
                  onChange={phone => setValue(`parents.${index}.phone`, phone)}
                  inputProps={{
                    id: `parent-${index}-phone`,
                  }}
                  containerClass="mt-1"
                  inputClass="!w-full !px-3 !py-2 !border !border-gray-300 !rounded-md !shadow-sm focus:!outline-none focus:!ring-indigo-500 focus:!border-indigo-500 !sm:text-sm !pl-12"
                  buttonClass="!border !border-gray-300 !rounded-l-md !shadow-sm"
                  buttonStyle={{ borderRight: 0 }}
                  containerStyle={{ minWidth: '100%' }}
                  dropdownStyle={{ width: '300px' }}
                />
              </div>

              <div>
                <label className="form_label">Email</label>
                <input
                  className="form_input"
                  type="email"
                  {...register(`parents.${index}.email`)}
                />
              </div>

              <div>
                <label className="form_label">Date of Birth</label>
                <input
                  className="form_input"
                  type="date"
                  {...register(`parents.${index}.dob`)}
                />
              </div>

              <div className="col-span-2">
                <label className="form_label">Notes</label>
                <textarea
                  className="form_input"
                  {...register(`parents.${index}.notes`)}
                />
              </div>
            </div>
          </div>
        ))}

        {parentFields.length < 2 && (
          <button
            type="button"
            onClick={() => appendParent({
              first_name: "",
              last_name: "",
              relationship: "",
              gender: "",
              phone: "",
              email: "",
              dob: null,
              notes: ""
            })}
                className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Add Parent
          </button>
        )}
      </div>

      <div className="mb-8">
        <button
          type="button"
          className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          onClick={() => setIsEnrollmentExpanded(!isEnrollmentExpanded)}
        >
          <h3 className="text-lg font-semibold">Enrollment Details</h3>
          {isEnrollmentExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </button>

        {isEnrollmentExpanded && (
        <div className="p-4 space-y-4">
          {enrollmentFields.map((field, index) => (
            <div
              key={field.id}
              className="p-4 border rounded-lg space-y-4"
            >
              <div className="flex justify-between items-center mb-2">
                <h4 className="font-medium">#{index + 1}</h4>
                {enrollmentFields.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeEnrollment(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                )}
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="form_label">Academic Year <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.enrollments?.[index]?.academic_year_id && requiredFieldClass}`}
                    {...register(`enrollments.${index}.academic_year_id`, { required: "Academic year is required" })}
                  >
                    <option value="">Select Academic Year</option>
                    {academicYears?.data?.map((year) => (
                      <option key={year.id} value={year.id}>
                        {year.name}
                      </option>
                    ))}
                  </select>
                  {errors.enrollments?.[index]?.academic_year_id && (
                    <span className={errorMessageClass}>{errors.enrollments[index].academic_year_id.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Class <span className="text-red-500">*</span></label>
                  <select
                    className={`form_input ${errors.enrollments?.[index]?.class_id && requiredFieldClass}`}
                    {...register(`enrollments.${index}.class_id`, { required: "Class is required" })}
                  >
                    <option value="">Select Class</option>
                    {classes?.map((cls) => (
                      <option key={cls.id} value={cls.id}>
                        {cls.name} ({cls.sections?.length || 0} sections)
                      </option>
                    ))}
                  </select>
                  {errors.enrollments?.[index]?.class_id && (
                    <span className={errorMessageClass}>{errors.enrollments[index].class_id.message}</span>
                  )}
                </div>

                <div>
                  <label className="form_label">Section</label>
                  <select
                    className={`form_input ${errors.enrollments?.[index]?.section_id && requiredFieldClass}`}
                    {...register(`enrollments.${index}.section_id`)}
                    disabled={!watch(`enrollments.${index}.class_id`)}
                  >
                    <option value="">Select Section</option>
                    {watch(`enrollments.${index}.class_id`) && 
                      classes
                        .find(cls => cls.id.toString() === watch(`enrollments.${index}.class_id`).toString())
                        ?.sections?.map(section => (
                          <option key={section.id} value={section.id}>
                            {section.name}
                          </option>
                        ))
                    }
                  </select>
                  {errors.enrollments?.[index]?.section_id && (
                    <span className={errorMessageClass}>{errors.enrollments[index].section_id.message}</span>
                  )}
                </div>
              </div>
            </div>
          ))}

            <button
              type="button"
              onClick={() => appendEnrollment({ class_id: "", academic_year_id: "", section_id: "" })}
              className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Add Enrollment
            </button>
          </div>
        )}
      </div>

      {/* Image Modal */}
      {showImageModal && profilePicture && (
        <div 
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={() => setShowImageModal(false)}
        >
          <div 
            className="relative bg-white rounded-lg p-2 max-w-4xl max-h-[90vh] mx-4"
            onClick={e => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none z-10"
              onClick={() => setShowImageModal(false)}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <img
              src={profilePicture}
              alt="Profile Large"
              className="max-w-full max-h-[85vh] object-contain rounded-lg"
            />
          </div>
        </div>
      )}

      {/* Submit Button */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={() => navigate(-1)}
          className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
        >
          Cancel
        </button>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? (
            <LoadingDots text={id ? 'Updating' : 'Creating'} />
          ) : (
            id ? 'Update' : 'Create'
          )}
        </button>
      </div>
    </form>
  );
};

export default StudentForm;
