import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createUser, deleteUser, getAllUsers, updateUser, activateUser, deactivateUser } from "../../Api/userServices";
import { toast } from "react-toastify";

export const useUserQuery = (page = 1, limit = 10) => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: ["users", page],
    queryFn: () => getAllUsers(page, limit),
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to fetch users. Please try again.");
    },
  });

  const createMutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create user. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateUser(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update user. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete user. Please try again.");
    },
  });

  const activateMutation = useMutation({
    mutationFn: activateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User activated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to activate user. Please try again.");
    },
  });

  const deactivateMutation = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User deactivated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to deactivate user. Please try again.");
    },
  });

  return {
    data,
    isLoading,
    error,
    createMutation,
    editMutation,
    deleteMutation,
    activateMutation,
    deactivateMutation,
  };
};
