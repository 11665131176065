import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNotices } from '../../Api/noticesServices';
import { useNoticeQuery } from '../../hooks/notice/useNoticeQuery';
import confirmDelete from '../../lib/confirmDelete';
import dayjs from 'dayjs';

const NoticeList = () => {
  const navigate = useNavigate();
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNoticeId, setExpandedNoticeId] = useState(null);
  const [activeTag, setActiveTag] = useState(null);
  const { deleteMutation } = useNoticeQuery();

  useEffect(() => {
    fetchNotices();
  }, [activeTag]);

  const fetchNotices = async () => {
    try {
      setLoading(true);
      const data = await getNotices({ tag: activeTag });
      setNotices(Array.isArray(data) ? data : []);
      setError(null);
    } catch (error) {
      console.error('Error fetching notices:', error);
      setError('Failed to load notices. Please try again later.');
      setNotices([]);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/notices/edit/${id}`);
  };

  const handleDelete = (notice) => {
    confirmDelete(
      () => {
        return new Promise((resolve, reject) => {
          deleteMutation.mutate(notice.id, {
            onSuccess: () => {
              fetchNotices();
              resolve();
            },
            onError: (error) => {
              reject(error);
            }
          });
        });
      },
      'notice'
    );
  };

  const toggleExpand = (id) => {
    setExpandedNoticeId(expandedNoticeId === id ? null : id);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <div className="text-red-600 mb-4">{error}</div>
        <button
          onClick={fetchNotices}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-800">Notices</h1>
          {activeTag && (
            <div className="mt-2 flex items-center">
              <span className="text-sm text-gray-600">Filtered by tag:</span>
              <span className="ml-2 px-2 py-1 bg-blue-100 text-blue-800 text-sm rounded-full flex items-center">
                {activeTag}
                <button
                  onClick={() => setActiveTag(null)}
                  className="ml-2 text-blue-600 hover:text-blue-800"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </span>
            </div>
          )}
        </div>
        <button
          onClick={() => navigate('/notices/create')}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Create Notice
        </button>
      </div>

      {notices.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-600 mb-4">No notices available</p>
          <button
            onClick={() => navigate('/notices/create')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Create Your First Notice
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {notices.map((notice) => (
            <div
              key={notice.id}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div 
                className="p-6 cursor-pointer"
                onClick={() => toggleExpand(notice.id)}
              >
                <div className="flex justify-between items-start">
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    {notice.title}
                  </h2>
                  <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(notice.id);
                      }}
                      className="p-2 text-blue-600 hover:bg-blue-50 rounded-full"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(notice);
                      }}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
                
                <div className={`transition-all duration-300 ${expandedNoticeId === notice.id ? '' : 'line-clamp-3'}`}>
                  {notice.content.split('\n').map((paragraph, index) => (
                    <p key={index} className="text-gray-600 mb-2">{paragraph}</p>
                  ))}
                </div>

                <div className="mt-4 flex flex-wrap gap-2">
                  {notice.tags && notice.tags.map((tag) => (
                    <button
                      key={tag}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveTag(tag);
                      }}
                      className={`px-2 py-1 text-sm rounded-full transition-colors duration-200 ${
                        activeTag === tag 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                      }`}
                    >
                      {tag}
                    </button>
                  ))}
                </div>

                <div className="mt-4 text-sm text-gray-500 space-y-1">
                  <p>Published: {dayjs(notice.publish_date).format('MMM D, YYYY')}</p>
                  <p>Expires: {dayjs(notice.expiry_date).format('MMM D, YYYY')}</p>
                </div>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleExpand(notice.id);
                  }}
                  className="mt-4 text-blue-600 hover:text-blue-700 text-sm font-medium"
                >
                  {expandedNoticeId === notice.id ? 'Show Less' : 'Read More'}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NoticeList;
