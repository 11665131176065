// utils/confirmDelete.js
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const confirmDelete = async (onConfirm) => {
  const result = await MySwal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  });

  if (result.isConfirmed) {
    try {
      await onConfirm();
      await MySwal.fire('Deleted!', 'The item has been deleted.', 'success');
    } catch (error) {
      await MySwal.fire('Error!', 'Failed to delete the item.', 'error');
    }
  }
};

export default confirmDelete;
