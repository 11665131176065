import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSubject, deleteSubject, updateSubject } from "../../Api/subjectServices";
import { toast } from "react-toastify";

export const useSubjectQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createSubject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subjects"] });
      toast.success("Subject created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create subject. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateSubject(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subjects"] });
      toast.success("Subject updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update subject. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteSubject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subjects"] });
      toast.success("Subject deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete subject. Please try again.");
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
