import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllParents, deleteParent, editParent, createParent } from "../../Api/parentServices";
import usePagination from "../usePagination";
import { toast } from "react-toastify";

// Handles data fetching and pagination
export const useParentQuery = () => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } = usePagination();

  // Fetching parents with pagination
  const { data = { data: [], metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 } }, error, isLoading, isFetching } = useQuery({
    queryKey: ["parents", offset, limit],
    queryFn: () => getAllParents({ offset, limit }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: deleteParent,
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
      toast.success("Parent deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete parent. Please try again.");
    },
  });

  // Edit mutation
  const editMutation = useMutation({
    mutationFn: ({id, updatedData}) => editParent(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
      toast.success("Parent updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update parent. Please try again.");
    },
  });

  // Create Mutation
  const createMutation = useMutation({
    mutationFn: createParent,
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
      toast.success("Parent created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create parent. Please try again.");
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    pagination: {
      currentPage,
      totalPages,
      nextPage,
      prevPage,
      goToPage,
    },
    deleteMutation,
    editMutation,
    createMutation,
  };
};