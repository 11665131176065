import { useMemo } from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Edit, Eye, Trash } from "lucide-react";

export const useTableColumns = (handleDelete) => {
  return useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        id: "dob",
        header: "Date of Birth",
        accessorKey: "dob",
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
      },
      {
        id: "gender",
        header: "Gender",
        accessorKey: "gender",
      },
      {
        id: "phone",
        header: "Phone",
        accessorKey: "phone",
      },
      {
        id: "address",
        header: "Address",
        accessorKey: "address",
        cell:({row})=>{
          const address = row.original.address;
          if(!address) return "N/A";
          const {municipality='',district='',province='',ward=''} = row.original.address;
          return `${municipality},Ward-${ward},${district},${province}`
        }
      },
      {
        id: "action",
        header: "Action",
        enableSorting: false,
        canHide: false,

        cell: ({ row }) => (
          <div className="flex gap-2">
            <Link to={`/parent/edit/${row.original.id}`}>
              <Eye size={18} />
            </Link>
            <Link to={`/parent/edit/${row.original.id}`}>
              <Edit size={18} />
            </Link>

            <Link onClick={() => handleDelete(row.original.id)}>
              <Trash size={18} className="text-red-500" />
            </Link>
          </div>
        ),
      },
    ],
    [handleDelete]
  );
};
