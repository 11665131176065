import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserQuery } from '../../hooks/user/useUserQuery';
import Table from '../../components/common/Table';
import { createColumnHelper } from '@tanstack/react-table';

const ListUsers = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, deleteMutation, activateMutation, deactivateMutation } = useUserQuery(currentPage);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('email', {
      header: 'Email',
      cell: info => info.getValue(),
      minWidth: 200,
    }),
    columnHelper.accessor('username', {
      header: 'Username',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => `${row.first_name} ${row.last_name}`, {
      id: 'name',
      header: 'Name',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('phone', {
      header: 'Phone',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('roles', {
      header: 'Roles',
      cell: info => (
        <div className="flex flex-wrap gap-1">
          {info.getValue().map((role) => (
            <span
              key={role}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
            >
              {role}
            </span>
          ))}
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: info => (
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            info.getValue() === 'active'
              ? 'bg-green-100 text-green-800'
              : info.getValue() === 'inactive'
              ? 'bg-red-100 text-red-800'
              : 'bg-yellow-100 text-yellow-800'
          }`}
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('id', {
      header: 'Actions',
      cell: info => (
        <div className="inline-flex items-center" onClick={e => e.stopPropagation()}>
          {info.row.original.status === 'active' ? (
            <button
              onClick={() => deactivateMutation.mutate(info.getValue())}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full text-xs"
            >
              Deactivate
            </button>
          ) : (
            <button
              onClick={() => activateMutation.mutate(info.getValue())}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-full text-xs"
            >
              Activate
            </button>
          )}
          
        </div>
      ),
    }),
  ];

  const handleLink = () => "/users/create";

  const pagination = {
    currentPage: data?.currentPage || 1,
    totalPages: data?.totalPages || 1,
    nextPage: () => setCurrentPage(old => Math.min(old + 1, data?.totalPages || 1)),
    prevPage: () => setCurrentPage(old => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  return (
    <div className="container mx-auto p-4">
      <Table
        data={data?.users || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Users"
        baseEditPath="/users/edit"
      />
    </div>
  );
};

export default ListUsers;
