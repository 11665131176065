import axiosInstance from "../lib/axiosConfig";

export const uploadAttachment = async (id, file, referenceType) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('reference_type', referenceType);
  
  try {
    const { data } = await axiosInstance.post(`/attachments/${id}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadAttachment = async (id) => {
  try {
    const response = await axiosInstance.get(`/attachments/${id}/download`, {
      responseType: 'blob',
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error downloading attachment:', error);
    return null;
  }
};

// Cache for storing downloaded images with timestamps
const imageCache = new Map();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

export const revokeProfileImageUrl = (id) => {
  const cachedData = imageCache.get(id);
  if (cachedData?.url) {
    URL.revokeObjectURL(cachedData.url);
    imageCache.delete(id);
  }
};

export const getProfileImage = async (id) => {
  if (!id) return null;
  
  // Check cache first and validate timestamp
  const cachedData = imageCache.get(id);
  if (cachedData) {
    const { url, timestamp } = cachedData;
    if (Date.now() - timestamp < CACHE_DURATION) {
      return url;
    }
    // Cache expired, revoke the old URL and remove it
    URL.revokeObjectURL(cachedData.url);
    imageCache.delete(id);
  }

  try {
    const response = await axiosInstance.get(`/attachments/${id}/download`, {
      responseType: 'blob',
    });
    
    // Create new object URL
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const imageUrl = URL.createObjectURL(blob);
    
    // Store URL and timestamp in cache
    imageCache.set(id, { url: imageUrl, timestamp: Date.now() });
    return imageUrl;
  } catch (error) {
    console.error('Error getting profile image:', error);
    return null;
  }
};
