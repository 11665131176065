import React from 'react';
import NoticeForm from '../../components/Notice/NoticeForm';

const CreateNotice = () => {
  return (
    <div>
      <NoticeForm />
    </div>
  );
};

export default CreateNotice;
